import type { StyleRules, Theme } from '@material-ui/core/styles';

const selectStyles = (theme: Theme) => ({
  chip: {
    margin: `${theme.spacing(0.5)}px ${theme.spacing(0.25)}px`,
  },
  disabled: {
    opacity: theme.status.disabled.opacity,
  },
  disabledLabel: {
    color: 'rgba(0, 0, 0, 0.38)',
  },
  input: {
    color: 'inherit',
    display: 'flex',
    height: 'auto',
  },
  'input-filled': {
    margin: 0,
    padding: theme.spacing(1, 2),
  },
  'input-outlined': {
    margin: 0,
    padding: theme.spacing(1.25, 2),
  },
  'input-standard': {
    margin: '-2px 0',
    padding: 0,
  },
  noOptionsMessage: {
    padding: theme.spacing(1, 2),
  },
  paper: {
    left: 0,
    marginTop: theme.spacing(1),
    position: 'absolute',
    right: 0,
    zIndex: 11,
  },
  placeholder: {
    color: theme.placeholder,
    fontSize: '16px',
    margin: '0 !important',
    maxWidth: '96%', // Leave room for divider between text & dropdown icon
    overflow: 'hidden',
    padding: 0,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  root: {
    flexGrow: 1,
  },
  singleValue: {
    fontSize: 16,
    maxWidth: '96%', // Leave room for divider between text & dropdown icon
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  valueContainer: {
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    flexWrap: 'wrap',
    overflow: 'hidden',
  },
  wrap: {
    flexWrap: 'initial',
    whiteSpace: 'normal',
  },
} as const satisfies StyleRules);

export default selectStyles;
