import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@popmenu/admin-ui';
import { Typography } from '@popmenu/common-ui';

import BasicModal from '../../BasicModal';

interface BasicFormConfirmModalProps {
  closeModal: () => void;
  message?: MaybeFormattedMessage;
  onSubmit: () => void;
  showModal: boolean,
  title?: MaybeFormattedMessage;
}

const BasicFormConfirmModal = ({
  closeModal,
  message = 'Are you sure you want to save your changes?',
  onSubmit,
  showModal,
  title = 'Confirm Save',
}: BasicFormConfirmModalProps) => (
  <BasicModal
    actions={(
      <React.Fragment>
        <Button
          onClick={(e) => {
            e.stopPropagation();
            closeModal();
          }}
          variant="ghost"
        >
          <FormattedMessage id="basic_form.confirm_modal.cancel" defaultMessage="Cancel" />
        </Button>
        <Button
          data-cy="basic_form_confirm_button"
          onClick={() => {
            onSubmit();
            closeModal();
          }}
          variant="text"
        >
          <FormattedMessage id="basic_form.confirm_modal.confirm" defaultMessage="Confirm" />
        </Button>
      </React.Fragment>
      )}
    closeModal={closeModal}
    show={showModal}
    size="sm"
    title={title}
  >
    <Typography
      aria-live="assertive"
      gutterBottom
      role="alert"
      variant="body1"
    >
      {message}
    </Typography>
  </BasicModal>
);

export default BasicFormConfirmModal;
