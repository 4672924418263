import React from 'react';
import PropTypes from 'prop-types';
import GoogleReCAPTCHA from 'react-google-recaptcha';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@popmenu/common-ui';

import { withPopmenuConfig } from '../utils/withPopmenuConfig';

class Captcha extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      renderCaptcha: false,
    };
  }

  componentDidMount() {
    this.setState({ renderCaptcha: true });
  }

  componentDidCatch(err) {
    this.setState({ hasError: true });
    console.warn(`ReCAPTCHA error: ${err}`);
  }

  render() {
    if (!this.state.renderCaptcha) {
      return null;
    }
    if (this.state.hasError) {
      return (
        <Typography align="center">
          <FormattedMessage
            id="captch.loading_error"
            defaultMessage="Error loading ReCAPTCHA, refresh and try again"
          />
        </Typography>
      );
    }
    return (
      <GoogleReCAPTCHA
        onChange={this.props.onChange}
        sitekey={this.props.popmenuConfig.recaptchaSiteKey}
      />
    );
  }
}

Captcha.propTypes = {
  onChange: PropTypes.func.isRequired,
  popmenuConfig: PropTypes.shape({
    recaptchaSiteKey: PropTypes.string,
  }).isRequired,
};

export default withPopmenuConfig(Captcha);
