import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'react-form';

import { toInt } from '../../../utils/utils';

import FormGroup from './FormGroup';
import TextField from './TextField';

const TextFieldGroup = (props) => {
  const {
    characterLimit,
    defaultValue,
    field,
    helperText: _exclude,
    inline,
    InputProps,
    onTextChange,
    FormGroupProps,
    maxValue,
    maxValueNotice,
    preventNegativeValues,
    validate,
    size,
    triggerValidationOnInit,
    ...inputProps
  } = props;
  const initValue = useMemo(() => defaultValue, [defaultValue]);
  const { value, setValue, meta: { error } } = useField(field, { defaultIsTouched: triggerValidationOnInit, defaultValue: initValue, validate });
  const onChange = useCallback((e) => {
    const val = e.target.value;
    if (preventNegativeValues && (val < 0 || Number.isNaN(val))) return;

    setValue(val);
    if (onTextChange) onTextChange(val);
  }, [onTextChange, preventNegativeValues, setValue]);
  const onIncrementDown = useCallback(() => { setValue(toInt(value) - 1); }, [setValue, value]);
  const onIncrementUp = useCallback(() => { setValue(toInt(value) + 1); }, [setValue, value]);

  // Character length warning
  let helperText = null;
  if (characterLimit) {
    helperText = `${(value || '').length}/${characterLimit} characters`;
  }
  if (props.helperText) {
    if (typeof props.helperText === 'function') {
      helperText = props.helperText(error);
    } else {
      helperText = props.helperText;
    }
  }

  // Format validation error
  const errorTitle = props.title || props.placeholder;
  let errorMessage = error;
  let errorId;
  if (errorMessage && errorTitle) {
    errorId = `${field}-error`;
    if (typeof errorTitle === 'string') {
      errorMessage = `${errorTitle} ${errorMessage.toLowerCase()}`;
    } else {
      errorMessage = (
        <React.Fragment>
          {errorTitle} {errorMessage.toLowerCase()}
        </React.Fragment>
      );
    }
  }
  let noticeMessage;
  if (!errorMessage && maxValue && maxValue === value) {
    noticeMessage = maxValueNotice;
  }

  return (
    <FormGroup error={errorMessage} notice={noticeMessage} errorId={errorId} {...FormGroupProps}>
      <TextField
        characterLimit={characterLimit}
        error={!!errorMessage}
        field={field}
        helperText={helperText}
        maxValue={maxValue}
        onChange={onChange}
        onPaste={onChange}
        onCut={onChange}
        onIncrementDown={preventNegativeValues ? value > 0 && onIncrementDown : onIncrementDown}
        onIncrementUp={maxValue ? value < maxValue && onIncrementUp : onIncrementUp}
        size={size || 'medium'}
        value={value === null || value === undefined ? '' : String(value)}
        InputProps={{
          'aria-describedby': errorId,
          ...InputProps,
        }}
        {...inputProps}
      />
    </FormGroup>
  );
};

TextFieldGroup.defaultProps = {
  characterLimit: undefined,
  defaultValue: undefined,
  disabled: false,
  FormGroupProps: null,
  inline: undefined,
  InputProps: {},
  isRequiredTitleLabel: false,
  maxValue: null,
  maxValueNotice: null,
  multiline: false,
  onTextChange: null,
  placeholder: null,
  preventNegativeValues: false,
  showSteppers: false,
  step: null,
  style: null,
  title: null,
  titleTooltip: null,
  triggerValidationOnInit: false,
  type: 'text',
  validate: null,
  variant: 'outlined',
};

TextFieldGroup.propTypes = {
  characterLimit: PropTypes.number,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  field: PropTypes.string.isRequired,
  FormGroupProps: PropTypes.object,
  inline: PropTypes.bool,
  InputProps: PropTypes.object,
  isRequiredTitleLabel: PropTypes.bool,
  maxValue: PropTypes.number,
  maxValueNotice: PropTypes.string,
  multiline: PropTypes.bool,
  onTextChange: PropTypes.func,
  placeholder: PropTypes.string,
  preventNegativeValues: PropTypes.bool,
  showSteppers: PropTypes.bool,
  step: PropTypes.string,
  style: PropTypes.object,
  title: PropTypes.node,
  titleTooltip: PropTypes.string,
  triggerValidationOnInit: PropTypes.bool,
  type: PropTypes.string,
  validate: PropTypes.func,
  variant: PropTypes.oneOf(['filled', 'outlined', 'standard']),
};

export default TextFieldGroup;
