import { useRef } from 'react';

// Store an instance variable across renders without causing a rerender on update
const useVariable = <T>(initialValue: T) => {
  type FuncParam = (value: T) => T;
  type Setter = (param: T | FuncParam) => void;
  const ref = useRef<[T, Setter]>([
    initialValue,
    (param) => {
      // @ts-expect-error TODO sc-84792 cast to FuncParam
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      ref.current[0] = typeof param === 'function' ? param(ref.current[0]) : param;
    },
  ]);
  return ref.current;
};

export default useVariable;
