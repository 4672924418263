import React from 'react';
import PropTypes from 'prop-types';
import { FilledInput, FormControl, InputAdornment as MuiInputAdornment, InputLabel, OutlinedInput, Tooltip as MuiTooltip } from '@material-ui/core';
import { Icon } from '@popmenu/common-ui';
import { Help } from '@popmenu/web-icons';

class FileInput extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      labelWidth: 0,
    };
    this.inputLabelRef = React.createRef();
  }

  componentDidMount() {
    this.setState({
      labelWidth: this.inputLabelRef.current ? this.inputLabelRef.current.offsetWidth : 0,
    });
  }

  render() {
    const { className, error, fieldName, inputComponent, inputProps, onChange, title, titleTooltip, variant } = this.props;

    // Tooltip/End Adornment
    let endAdornment = null;
    if (titleTooltip) {
      endAdornment = (
        <MuiInputAdornment position="end">
          <MuiTooltip placement="top" title={titleTooltip}>
            <div>
              <Icon icon={Help} fontSize="default" />
            </div>
          </MuiTooltip>
        </MuiInputAdornment>
      );
    }

    return (
      <FormControl className={className} data-tour-id={this.props['data-tour-id']} fullWidth variant="outlined">
        {title && (
          <InputLabel error={error} htmlFor={fieldName} shrink>
            <span ref={this.inputLabelRef}>{title}</span>
          </InputLabel>
        )}
        {variant === 'filled' && (
          <FilledInput
            endAdornment={endAdornment}
            error={error}
            fullWidth
            id={fieldName}
            inputComponent={inputComponent}
            inputProps={inputProps}
            name={fieldName}
            onChange={onChange}
            type="file"
          />
        )}
        {variant === 'outlined' && (
          <OutlinedInput
            endAdornment={endAdornment}
            error={error}
            fullWidth
            id={fieldName}
            inputComponent={inputComponent}
            inputProps={inputProps}
            labelWidth={this.state.labelWidth}
            name={fieldName}
            notched
            onChange={onChange}
            type="file"
          />
        )}
      </FormControl>
    );
  }
}

FileInput.defaultProps = {
  accept: undefined,
  className: null,
  'data-tour-id': null,
  error: false,
  inputComponent: 'input',
  inputProps: {},
  title: null,
  titleTooltip: null,
  variant: 'outlined',
};

FileInput.propTypes = {
  accept: PropTypes.string,
  className: PropTypes.string,
  'data-tour-id': PropTypes.string,
  error: PropTypes.bool,
  fieldName: PropTypes.string.isRequired,
  inputComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.string, PropTypes.object]),
  inputProps: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  title: PropTypes.string,
  titleTooltip: PropTypes.string,
  variant: PropTypes.oneOf(['filled', 'outlined']),
};

export default FileInput;
