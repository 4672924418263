import type { StyleRules, Theme } from '@material-ui/core/styles';

import { readableFontColor } from '../../../utils/colors';

const dateTimePickerStyles = (theme: Theme) => ({
  buttonLabel: {
    '& .MuiPickersModal-withAdditionalAction': {
      '& button': {
        color: readableFontColor('#FFF', theme.palette.primary.main),
      },
    },
  },
  picker: {
    width: '100%',
  },
} as const satisfies StyleRules);

export default dateTimePickerStyles;
