import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@popmenu/common-ui';

import { classNames, withStyles } from '../../../utils/withStyles';
import formGroupStyles from '../../../assets/jss/shared/formGroupStyles';

import { AH, AHLevelProvider } from '../../../consumer/shared/AccessibleHeading';

const FormGroup = ({ children, className, classes, dataCy, dataTourId, error, errorId, notice, style, title }) => (
  <div
    className={classNames(
      'pm-form-group',
      className,
      classes.formGroup,
      error ? classes.formGroupError : null,
    )}
    data-cy={dataCy}
    data-tour-id={dataTourId}
    style={style}
  >
    {title && (
      <AH typography className={classes.title} gutterBottom variant="h6">
        {title}
      </AH>
    )}
    <AHLevelProvider>
      {children}
      {!error && notice && (
        <Typography className={classes.formGroupNoticeMessage} role="alert">
          {notice}
        </Typography>
      )}
      {error && (
        <Typography id={errorId} className={classes.formGroupErrorMessage} role="alert">
          {error}
        </Typography>
      )}
    </AHLevelProvider>
  </div>
);

FormGroup.defaultProps = {
  className: null,
  dataCy: null,
  dataTourId: null,
  error: null,
  errorId: null,
  notice: null,
  style: null,
  title: null,
};

FormGroup.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  dataCy: PropTypes.string,
  dataTourId: PropTypes.string,
  error: PropTypes.node,
  errorId: PropTypes.string,
  notice: PropTypes.node,
  style: PropTypes.object,
  title: PropTypes.string,
};

export default withStyles(formGroupStyles)(FormGroup);
