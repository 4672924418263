import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'react-form';
import { DatePicker, DateTimePicker, MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { startOfHour, startOfDay, addSeconds } from 'date-fns';
import { addPickerAriaLabelEventListeners, addPickerAriaLabels } from '~/admin/shared/aria/pickerAriaUtils';
import { classNames, withStyles } from '../../../utils/withStyles';
import dateTimePickerStyles from '../../../assets/jss/shared/dateTimePickerStyles';
import { formatDate } from '../../../utils/time';

import FormGroup from './FormGroup';

export class LocalUtils extends DateFnsUtils {
  format(value, formatString) {
    return formatDate(value, formatString, { utc: false });
  }
}

export class UtcUtils extends DateFnsUtils {
  format(value, formatString) {
    return formatDate(value, formatString, { utc: true });
  }
}

const handleOnOpen = (pickerType) => {
  setTimeout(() => {
    addPickerAriaLabels(pickerType);
    addPickerAriaLabelEventListeners(pickerType);
  }, 200);
};

const CustomDay = ({ day, dayComponent }) => {
  const dayDisabled = dayComponent.props.disabled || false;

  return (
    <div aria-label={day.toDateString()} aria-hidden={dayDisabled}>
      {dayComponent}
    </div>
  );
};

const DateTimePickerGroup = (props) => {
  const { classes, className, disabled, field, formGroupProps, onValueChanged, pickerType, placeholder, title, useISO, utc, validate, variant, PickerProps, ...restProps } = props;
  const { value, setValue, meta: { error } } = useField(field, { validate });
  let format;
  let PickerComponent;
  let defaultPlaceholder;
  let toPickerValue;
  let fromPickerValue;
  let views;
  let openTo;
  switch (pickerType) {
    case 'date':
      format = 'MM/dd/yyyy';
      PickerComponent = DatePicker;
      defaultPlaceholder = 'Select a date';
      toPickerValue = v => v && formatDate(v, 'ISO', { utc });
      fromPickerValue = v => v && formatDate(v, useISO ? 'ISO' : 'yyyy-MM-dd', { utc });
      break;
    case 'datetime':
      format = 'MM/dd/yyyy hh:mm a zzz';
      PickerComponent = DateTimePicker;
      defaultPlaceholder = 'Select a date and time';
      toPickerValue = v => v;
      fromPickerValue = v => v && v.toISOString();
      break;
    case 'month':
      format = props.format || 'MM/yyyy';
      PickerComponent = DatePicker;
      defaultPlaceholder = 'Select a month';
      toPickerValue = v => v;
      fromPickerValue = v => formatDate(v, 'yyyy-MM', { utc: false });
      views = ['year', 'month'];
      openTo = 'month';
      break;
    case 'time':
      format = 'hh:mm a';
      PickerComponent = TimePicker;
      defaultPlaceholder = 'Select a time';
      // Seconds of day to date object
      toPickerValue = v => v && addSeconds(startOfDay(new Date()), v);
      fromPickerValue = v => v && (v.getSeconds() + (60 * v.getMinutes()) + (60 * 60 * v.getHours()));
      break;
    default:
      console.warn(`[POPMENU] Invalid DateTimePickerGroup.pickerType (${pickerType})`);
      return null;
  }
  const example = formatDate(Date.now(), format);
  return (
    <MuiPickersUtilsProvider utils={utc ? UtcUtils : LocalUtils}>
      <FormGroup error={error} {...formGroupProps}>
        <PickerComponent
          allowKeyboardControl
          autoOk={false}
          className={classNames(classes.picker, className)}
          clearable={!validate}
          DialogProps={{ className: classes.buttonLabel }}
          disabled={disabled}
          error={!!error}
          format={format}
          InputProps={{
            inputProps: {
              'aria-label': placeholder || `${title || defaultPlaceholder} (${example})`,
              'data-testid': 'dateTimePickerGroup',
            },
          }}
          initialFocusedDate={startOfHour(new Date())}
          inputVariant={variant}
          label={title}
          name={field}
          onOpen={() => handleOnOpen(pickerType || 'datetime')}
          onChange={(newDate, newValue) => {
            try {
              setValue(fromPickerValue(newDate));
              onValueChanged(newDate);
            } catch (err) {
              console.log(`[POPMENU] Invalid date: ${newValue}`);
            }
          }}
          openTo={openTo}
          placeholder={placeholder || `${title || defaultPlaceholder} (${example})`}
          value={toPickerValue(value) || null}
          variant="dialog"
          leftArrowButtonProps={{ 'aria-label': 'Previous month' }}
          rightArrowButtonProps={{ 'aria-label': 'Next month' }}
          renderDay={(day, selectedDate, isInCurrentMonth, dayComponent) => <CustomDay day={day} dayComponent={dayComponent} />}
          views={views}
          {...PickerProps}
          {...restProps}
        />
      </FormGroup>
    </MuiPickersUtilsProvider>
  );
};

DateTimePickerGroup.defaultProps = {
  className: null,
  disabled: false,
  disablePast: false,
  format: null,
  formGroupProps: {},
  onValueChanged: () => {},
  PickerProps: undefined,
  pickerType: 'datetime',
  placeholder: null,
  title: null,
  useISO: false,
  utc: false,
  validate: null,
  variant: 'outlined',
};

DateTimePickerGroup.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  disablePast: PropTypes.bool,
  field: PropTypes.string.isRequired,
  format: PropTypes.string,
  formGroupProps: PropTypes.object,
  onValueChanged: PropTypes.func,
  /* Explicit prop for adding and overriding props applied to PickerComponent. This is a way around prop name collision like the `variant` prop. */
  PickerProps: PropTypes.object,
  pickerType: PropTypes.oneOf(['date', 'datetime', 'month', 'time']),
  placeholder: PropTypes.string,
  title: PropTypes.string,
  useISO: PropTypes.bool,
  utc: PropTypes.bool,
  validate: PropTypes.func,
  variant: PropTypes.oneOf(['filled', 'outlined', 'standard']),
};

export default withStyles(dateTimePickerStyles)(DateTimePickerGroup);
