import { type DependencyList, useMemo } from 'react';
import stringify from 'json-stable-stringify';

// Expands objects & arrays provided as useMemo dependencies and joins as a string for a hacky non-reference equality comparison
const expandDeps = (deps: DependencyList) => {
  const expandedDeps: unknown[] = [];
  deps.forEach((dep) => {
    if (dep && typeof dep === 'object') {
      if (Array.isArray(dep)) {
        dep.forEach((value) => {
          if (typeof (value as { label?: unknown }).label === 'string') {
            expandedDeps.push(stringify(value));
          } else {
            expandedDeps.push(stringify((value as { value?: unknown }).value));
          }
        });
      } else {
        expandedDeps.push(stringify(dep));
      }
    } else {
      expandedDeps.push(dep);
    }
  });
  const joinedDeps = expandedDeps.join(',');
  // console.log('joinedDeps', joinedDeps);
  return [joinedDeps];
};

const useMemoDeep = <T>(callback: () => T, plainDeps: DependencyList) => {
  let deps: DependencyList;
  if (Array.isArray(plainDeps)) {
    deps = expandDeps(plainDeps);
  } else {
    console.warn('[POPMENU] useMemoDeep: Argument must be an array!');
    deps = [];
  }
  return useMemo(callback, deps); // eslint-disable-line react-hooks/exhaustive-deps
};

export default useMemoDeep;
