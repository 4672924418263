type PickerType = 'date' | 'time' | 'datetime';
type DayOfWeek = 'Su' | 'Mo' | 'Tu' | 'We' | 'Th' | 'Fri' | 'Sa';
type DayOfWeekAbbr = 'Sunday' | 'Monday' | 'Tuesday' | 'Wednesday' | 'Thursday' | 'Friday' | 'Saturday';
type AbbrLookup = Record<DayOfWeek, DayOfWeekAbbr>;

// ADA compliance day of week abbreviations lookup
const abbrLookup: AbbrLookup = {
  Su: 'Sunday',
  Mo: 'Monday', // eslint-disable-line sort-keys
  Tu: 'Tuesday',
  We: 'Wednesday',
  Th: 'Thursday', // eslint-disable-line sort-keys
  Fri: 'Friday', // eslint-disable-line sort-keys
  Sa: 'Saturday',
};

const getDayAbbr = (dayOfWeek: DayOfWeek): DayOfWeekAbbr => abbrLookup[dayOfWeek];

const toolbarButtonLookup = {
  date: ['Year', 'Month', 'Day'],
  datetime: ['Year', 'Month & Day', 'Hour', 'Minute'],
  time: ['Hour', 'Minute'],
};

const getPickerModal = (): HTMLElement | null => document.querySelector<HTMLElement>('.MuiPickersModal-dialogRoot');

const addCalendarAriaLabels = (modal: Element): void => {
  const monthDisplayEl = modal.querySelector('.MuiPickersCalendarHeader-transitionContainer > p');
  monthDisplayEl?.setAttribute('aria-live', 'assertive');
  monthDisplayEl?.setAttribute('aria-relevant', 'all');
  modal.querySelector('.MuiPickersCalendarHeader-daysHeader')?.setAttribute('role', 'row');
  modal.querySelectorAll('.MuiPickersCalendarHeader-dayLabel')
    .forEach((weekdayHeaderEl) => {
      weekdayHeaderEl.setAttribute('role', 'columnheader');
      const abbr = getDayAbbr(weekdayHeaderEl.textContent as DayOfWeek);
      if (abbr) {
        weekdayHeaderEl.setAttribute('abbr', abbr);
      }
    });
  // Hide the text content from screen readers, there is a more descriptive aria-label
  modal.querySelectorAll('.MuiPickersDay-day > span.MuiIconButton-label > p').forEach((dayLabelEl) => {
    dayLabelEl.setAttribute('aria-hidden', 'true');
  });
};

const getTimePrefixAndSuffix = (modal: Element): [string, string] => {
  const selectedToolbarButtonLabel = document.querySelector('.MuiPickersToolbarText-toolbarBtnSelected')
    ?.closest('button')
    ?.getAttribute('aria-label');
  const isHourSelected = selectedToolbarButtonLabel === 'Hour';
  if (isHourSelected) return ['', ' o\'clock'];

  const hourButton = [...modal.querySelectorAll('.MuiPickersToolbarButton-toolbarBtn')].find((button: Element) => button?.textContent?.match(/^\d{2}$/));
  const selectedHour = hourButton?.textContent?.replace(/^0+/, '');
  const labelPrefix = selectedHour ? `${selectedHour}:` : '';
  return [labelPrefix, ''];
};

const addClockAriaLabels = (modal: Element): void => {
  const [labelPrefix, labelSuffix] = getTimePrefixAndSuffix(modal);
  modal.querySelectorAll('.MuiPickersClockNumber-clockNumber')
    .forEach((hourSelectorEl) => {
      hourSelectorEl.setAttribute('role', 'button');
      hourSelectorEl.setAttribute('tabindex', '0');
      hourSelectorEl.setAttribute('aria-label', `${labelPrefix}${hourSelectorEl.textContent}${labelSuffix}`);
      // eslint-disable-next-line no-param-reassign -- This is required to hide the text content from screen readers
      hourSelectorEl.innerHTML = `<span aria-hidden="true">${hourSelectorEl.textContent}</span>`;
    });
};

const addHeaderAriaLabels = (pickerType: PickerType, modal: Element): void => {
  const modalLabel = pickerType === 'date' ? 'Choose Date' : pickerType === 'time' ? 'Choose Time' : 'Choose Date and Time';
  modal.setAttribute('aria-label', modalLabel);
  modal.setAttribute('role', 'dialog');
  modal.setAttribute('aria-modal', 'true');
  modal.querySelectorAll('.MuiPickersToolbarButton-toolbarBtn').forEach((buttonEl, i) => {
    const buttonLabel = toolbarButtonLookup[pickerType][i];
    if (buttonLabel) {
      buttonEl.setAttribute('aria-label', buttonLabel);
      buttonEl.setAttribute('role', 'button');
    }
  });
};

const focusSiblingElement = (currentClockNumber: HTMLElement, elementSelector: string, nextOrPrevious: 'next' | 'previous'): void => {
  const clockNumbers: HTMLElement[] = [...document.querySelectorAll<HTMLElement>(elementSelector)];
  let siblingI = clockNumbers.indexOf(currentClockNumber) + (nextOrPrevious === 'next' ? 1 : -1);
  if (siblingI < 0) {
    siblingI = clockNumbers.length - 1;
  } else if (siblingI >= clockNumbers.length) {
    siblingI = 0;
  }
  clockNumbers[siblingI]?.focus();
};

const focusNextClockNumber = (currentClockNumber: HTMLElement): void => {
  focusSiblingElement(currentClockNumber, '.MuiPickersClockNumber-clockNumber', 'next');
};

const focusPreviousClockNumber = (currentClockNumber: HTMLElement): void => {
  focusSiblingElement(currentClockNumber, '.MuiPickersClockNumber-clockNumber', 'previous');
};

const focusNextYear = (currentSelectedYear: HTMLElement): void => {
  focusSiblingElement(currentSelectedYear, '.MuiPickersYear-root', 'next');
};

const focusPreviousYear = (currentSelectedYear: HTMLElement): void => {
  focusSiblingElement(currentSelectedYear, '.MuiPickersYear-root', 'previous');
};

// The tabs are redundant for those using screen readers
const hideDateTimeTabs = (modal: Element): void => {
  modal.querySelectorAll('button.MuiTab-root').forEach((dateTimeTab) => {
    dateTimeTab.setAttribute('tabindex', '-1');
    dateTimeTab.setAttribute('aria-hidden', 'true');
  });
};

export const addPickerAriaLabels = (pickerType: PickerType): void => {
  const modal = getPickerModal();
  if (!modal) return;

  addHeaderAriaLabels(pickerType, modal);
  switch (pickerType) {
    case 'date':
      addCalendarAriaLabels(modal);
      break;
    case 'time':
      addClockAriaLabels(modal);
      break;
    case 'datetime':
      addCalendarAriaLabels(modal);
      addClockAriaLabels(modal);
      hideDateTimeTabs(modal);
      break;
  }
};

// for ADA compliance we need to simulate a click event on the element that is focused
// it's odd but I found no other solution to make the keyboard navigation work
const clickAtElementCenter = (element: Element): void => {
  const { top, left, width, height } = element.getBoundingClientRect();
  const centerX = left + width / 2;
  const centerY = top + height / 2;
  const elementOrMaskElement = document.elementFromPoint(centerX, centerY);
  if (!elementOrMaskElement) return;

  ['mousedown', 'mouseup', 'click'].forEach((eventName) => {
    elementOrMaskElement.dispatchEvent(new MouseEvent(eventName, { bubbles: true, cancelable: true, clientX: centerX, clientY: centerY, view: window }));
  });
};

export const addPickerAriaLabelEventListeners = (pickerType: PickerType): void => {
  const delayedAddPickerAriaLabels = (delay: number = 100) => setTimeout(() => addPickerAriaLabels(pickerType), delay);

  const modal = getPickerModal();
  if (!modal) return;

  const keydownHandler: EventListenerObject = {
    handleEvent: (event: KeyboardEvent) => {
      const target = event.target as Element;
      const isClockNumber = target?.classList?.contains('MuiPickersClockNumber-clockNumber');
      const isYearSelector = target?.classList?.contains('MuiPickersYear-root');
      const isKeySpace = ['Space', ' ', 'Enter'].includes(event.key);

      if (isKeySpace && target.textContent !== 'am' && target.textContent !== 'pm') {
        const pickerModal = getPickerModal();
        if (isClockNumber) {
          clickAtElementCenter(target); // this clicks at the same spot but on the mask above it which has the event listener
        }
        // delay the focus to give the browser time to update the DOM
        setTimeout(() => {
          pickerModal?.querySelector<HTMLElement>('.MuiPickersYear-yearSelected, .MuiPickersDay-daySelected, .MuiPickersClockNumber-clockNumberSelected')?.focus();
        }, 400);
      } else if (['ArrowDown', 'ArrowRight'].includes(event.key) && isClockNumber) {
        event.preventDefault();
        focusNextClockNumber(target as HTMLElement);
      } else if (['ArrowUp', 'ArrowLeft'].includes(event.key) && isClockNumber) {
        event.preventDefault();
        focusPreviousClockNumber(target as HTMLElement);
      } else if (event.key === 'ArrowDown' && isYearSelector) {
        event.preventDefault();
        focusNextYear(target as HTMLElement);
      } else if (event.key === 'ArrowUp' && isYearSelector) {
        event.preventDefault();
        focusPreviousYear(target as HTMLElement);
      }
      delayedAddPickerAriaLabels();
    },
  };
  modal.addEventListener('keydown', keydownHandler);
  modal.addEventListener('click', () => delayedAddPickerAriaLabels());
};
