import type { StyleRules, Theme } from '@material-ui/core/styles';

const formGroupStyles = (theme: Theme) => ({
  formGroup: {
    '& .fr-box': {
      '& .fr-iframe': {
        borderRadius: '0 0 3px 3px !important',
        minHeight: '200px',
        padding: '0',
      },
      '& .fr-toolbar': {
        '& .fr-newline': {
          background: '#cccccc',
          marginLeft: 0,
          marginRight: 0,
        },
        borderRadius: '4px 4px 0 0 !important',
      },
      '& .fr-wrapper': {
        borderBottomColor: '#cccccc !important',
        borderRadius: '0 0 4px 4px !important',
      },
      '& .second-toolbar': {
        border: 'none',
      },
      '& textarea.fr-code': {
        borderRadius: '4px',
        minHeight: '200px',
      },
    },
    '&:last-child': {
      marginBottom: 0,
    },
    marginBottom: theme.spacing(3),
  },
  formGroupError: {
    '& .fr-box': {
      '& .fr-toolbar': {
        border: '2px solid red !important',
        borderBottom: '0 !important',
      },
      '& .fr-wrapper': {
        border: '2px solid red !important',
        borderTop: '0 !important',
      },
    },
    '& .pm-jss-dark-MuiOutlinedInput-input': {
      color: theme.palette.error.main,
    },
    '& .pm-jss-dark-MuiTypography-root': {
      color: theme.palette.error.main,
    },
    '& input': {
      color: '#CC0000',
    },
  },
  formGroupErrorMessage: {
    color: '#CC0000',
    marginTop: theme.spacing(1),
  },
  formGroupNoticeMessage: {
    color: '#E57E45',
    marginTop: theme.spacing(1),
  },
  title: {
    fontSize: '1em',
    fontWeight: 'normal',
    opacity: 0.7,
  },
} as const satisfies StyleRules);

export default formGroupStyles;
