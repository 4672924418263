import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'react-form';

import { serializeFile } from '../../../utils/files';

import FileInput from './FileInput';
import FormGroup from './FormGroup';

const FileUploadGroup = (props) => {
  const { className, field, onChange, title, validate, variant, ...inputProps } = props;
  const fieldName = `${field}DataUrl`;
  const { setValue, meta: { error } } = useField(fieldName, { validate });
  return (
    <FormGroup>
      <FileInput
        className={className}
        error={!!error}
        fieldName={fieldName}
        inputProps={inputProps}
        onChange={(e) => {
          const file = e && e.target && e.target.files[0];
          if (file) {
            serializeFile(file, (fileDataUrl) => {
              setValue(fileDataUrl || null);
            });
          }
          if (typeof onChange === 'function') {
            onChange(e);
          }
          return false;
        }}
        title={title}
        variant={variant}
      />
    </FormGroup>
  );
};

FileUploadGroup.defaultProps = {
  className: null,
  onChange: null,
  title: null,
  validate: null,
  variant: 'outlined',
};

FileUploadGroup.propTypes = {
  className: PropTypes.string,
  field: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  title: PropTypes.string,
  validate: PropTypes.func,
  variant: PropTypes.oneOf(['filled', 'outlined']),
};

export default FileUploadGroup;
