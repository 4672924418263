import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useFormContext } from 'react-form';
import { CircularProgress, makeStyles } from '@material-ui/core';
import { Button } from '@popmenu/admin-ui';

import { classNames } from '../../../utils/withStyles';
import formStyles from '../../../assets/jss/shared/formStyles';

import Grid from '../../../shared/Grid';

export type SubmitGroupProps = {
  block?: boolean,
  ButtonProps?: Partial<React.ComponentProps<typeof Button>>,
  CancelButtonProps?: Partial<React.ComponentProps<typeof Button>>,
  children?: React.ReactNode,
  className?: string,
  'data-cy'?: string,
  'data-tour-id'?: string,
  disabled?: boolean,
  id?: string,
  inline?: boolean,
  justify?: 'center' | 'flex-end' | 'flex-start',
  manualSubmit?:boolean,
  loading?: boolean,
  onCancel?: React.MouseEventHandler<HTMLButtonElement>,
  onClick?: React.MouseEventHandler<HTMLButtonElement>,
  size?: 'small' | 'medium' | 'large' | 'xl' | undefined,
  submitGroupButtonContainerStyle?: string,
  title?: React.ReactNode | string,
  variant?: 'outlined' | 'contained' | 'text',
};

const useStyles = makeStyles(formStyles);

const resolveVariant = (variant: SubmitGroupProps['variant']) => {
  switch (variant) {
    case 'outlined':
      return 'secondary';
    case 'contained':
      return 'primary';
    case 'text':
      return 'text';
    default:
      return 'primary';
  }
};

const resolveSize = (size: SubmitGroupProps['size']) => {
  if (size === undefined) return undefined;
  switch (size) {
    case 'small':
      return 'small';
    case 'medium':
      return 'medium';
    case 'large':
      return 'large';
    case 'xl':
      return 'large';
    default:
      return undefined;
  }
};

const SubmitGroup = ({
  ButtonProps = {},
  block = false,
  loading: loadingProp = false,
  CancelButtonProps = {},
  children = undefined,
  className = undefined,
  'data-cy': dataCy = 'submit',
  'data-tour-id': dataTourId,
  disabled = false,
  id = undefined,
  inline = false,
  justify = 'flex-end',
  manualSubmit = false,
  onCancel = undefined,
  onClick = undefined,
  size = undefined,
  submitGroupButtonContainerStyle = undefined,
  title = 'Save',
  variant = 'contained',
}: SubmitGroupProps) => {
  // Loading state from form metadata
  const { meta: { isValid, isSubmitting } } = useFormContext();
  const loading = loadingProp || isSubmitting;
  const classes = useStyles();
  // Submit button
  const button = (
    <Button
      fullWidth={block}
      className={classNames(classes.submitButton, className)}
      data-cy={dataCy}
      data-tour-id={dataTourId}
      data-testid={'submit-group-button'}
      disabled={disabled || loading || !isValid}
      id={id}
      onClick={onClick}
      size={resolveSize(size)}
      type={manualSubmit && typeof onClick === 'function' ? 'button' : 'submit'}
      variant={resolveVariant(variant)}
      {...ButtonProps}
    >
      {loading && (<CircularProgress className={classes.submitButtonSpinner} size={16} />)} {title}
    </Button>
  );
  if (inline) {
    return button;
  }

  // Cancel button
  let cancelButton = null;
  if (typeof onCancel === 'function') {
    cancelButton = (
      <Button
        className={classes.cancelButton}
        data-cy="cancel"
        data-testid={'cancel-button'}
        disabled={disabled || loading}
        onClick={onCancel}
        size={resolveSize(size)}
        type="button"
        variant="text"
        {...CancelButtonProps}
      >
        <FormattedMessage id="forms.cancel" defaultMessage="Cancel" />
      </Button>
    );
  }
  return (
    <Grid
      className={classNames(className, classes.submitGroup)}
      container
      inline={!block}
      justify={justify}
    >
      {cancelButton && (
        <Grid item>
          {cancelButton}
        </Grid>
      )}
      <Grid item xs={block ? 12 : undefined} className={classNames([size === 'xl' ? classes.extraLarge : null, submitGroupButtonContainerStyle])}>
        {button}
      </Grid>
      {children && (
        <Grid item>
          {children}
        </Grid>
      )}
    </Grid>
  );
};

export default SubmitGroup;
