export const inGroupsOf = <T>(array: T[], n: number): T[][] => {
  const groups: T[][] = [];
  for (let i = 0, j = 0; i < array.length; i += 1) {
    if (i >= n && i % n === 0) {
      j += 1;
    }
    groups[j] ||= [];
    // array[i] and groups[j] exist and we want to push even if array[i] is null or undefined
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    groups[j]!.push(array[i]!);
  }
  return groups;
};

// The type can be made to use correct nested property names after `.`, but the complexity isn't worth it
// https://stackoverflow.com/questions/58434389/typescript-deep-keyof-of-a-nested-object
type NestedKeyOf<T> = (keyof T & string) | `${keyof T & string}.${string}`;

const isNullishOrNaN = (value: unknown) => value == null || Number.isNaN(value);

export const sortByKey = <T extends object>(array: Optional<T[]>, key: NestedKeyOf<T> | NestedKeyOf<T>[], caseInsensitive = true): T[] => {
  if (array == null) {
    return [];
  }
  if (key == null) {
    console.error((new Error('[POPMENU] sortByKey: key is nullish, invalid call')).stack);
    return array;
  }
  const keyParts = (Array.isArray(key) ? key : [key]).map(x => x.split('.'));
  const getKey = (obj: T) => keyParts.map((nested) => {
    let value: unknown = obj;
    nested.forEach((part) => {
      if (value == null) {
        value = undefined;
      } else {
        value = (value as Record<string, unknown>)[part];
      }
    });
    return value;
  });
  return array.slice().sort((a, b) => {
    const keyA = getKey(a);
    const keyB = getKey(b);
    for (let i = 0; i < keyA.length; i += 1) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- need any for comparison at the end to work
      let propA: any = keyA[i];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- need any for comparison at the end to work
      let propB: any = keyB[i];
      const propAIsIncomparable = isNullishOrNaN(propA);
      const propBIsIncomparable = isNullishOrNaN(propB);
      if (propAIsIncomparable && propBIsIncomparable) {
        // eslint-disable-next-line no-continue
        continue;
      }
      if (propAIsIncomparable) {
        return 1;
      }
      if (propBIsIncomparable) {
        return -1;
      }
      if (caseInsensitive) {
        if (typeof propA === 'string') {
          propA = propA.toLowerCase();
        }
        if (typeof propB === 'string') {
          propB = propB.toLowerCase();
        }
      }
      if (propA > propB) {
        return 1;
      }
      if (propA < propB) {
        return -1;
      }
    }
    return 0;
  });
};

// Filters out all falsy values like `array.filter(Boolean)`, but has a better return type.
// When replacing `.filter(Boolean)` calls, consider if you want to exclude only null/undefined instead.
// If yes, use `filterNonNullish`.
export const filterTruthy = <T>(array: T[]) => array.filter(Boolean) as NonNullable<T>[];

// Filters out `null` and `undefined` like `array.filter(x => x != null)`, but has a better return type.
export const filterNonNullish = <T>(array: T[]) => array.filter(x => x != null) as NonNullable<T>[];
