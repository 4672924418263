import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'react-form';

import useMemoDeep from '../../../utils/useMemoDeep';

import FormGroup from './FormGroup';
import Select from './Select';

const SelectGroup = (props) => {
  const { disabled, field, FormGroupProps, isClearable, isMulti, onMenuOpen, onMenuClose, onSelected, options, startIcon, title, titleTooltip, validate, filterOption, ...selectProps } = props;
  const { hoverOptions, placeholder } = props;

  const { value, setValue, meta: { error } } = useField(field, { validate });
  const onChange = useCallback((params) => {
    let newValue;
    if (isMulti) {
      // Multi params: [{ label: "Label 1", value: "Value 1" }]
      newValue = (params || []).map(({ value: v }) => v);
    } else {
      // Single params: { label: "Label", value: "Value" }
      newValue = (params || {}).value;
    }
    if (typeof newValue === 'undefined') {
      newValue = null;
    }
    if (typeof onSelected === 'function') {
      onSelected(newValue);
    }
    setValue(newValue);
  }, [isMulti, setValue, onSelected]);

  const selectOptions = useMemoDeep(() => options.map(({ value: v, ...option }) => ({
    ...option,
    value: v,
  })), [options]);

  // Format validation error
  let errorMessage = error;
  if (errorMessage && (title || placeholder)) {
    errorMessage = `${title || placeholder} ${errorMessage.toLowerCase()}`;
  }

  return (
    <FormGroup {...FormGroupProps} error={errorMessage}>
      <Select
        aria-label={props['aria-label']}
        disabled={disabled}
        helperText={props.helperText}
        isClearable={typeof isClearable !== 'undefined' ? isClearable : !validate}
        isRequiredTitleLabel={props.isRequiredTitleLabel}
        isMulti={isMulti}
        inputClassName={props.className}
        name={field}
        onChange={onChange}
        onMenuClose={onMenuClose}
        onMenuOpen={onMenuOpen}
        options={selectOptions}
        hoverOptions={hoverOptions}
        startIcon={startIcon}
        title={title}
        value={value}
        filterOption={filterOption}
        {...selectProps}
      />
    </FormGroup>
  );
};

SelectGroup.defaultProps = {
  'aria-label': null,
  async: undefined,
  chipStyles: {},
  className: null,
  disabled: false,
  filterOption: undefined,
  FormGroupProps: undefined,
  helperText: null,
  hideDropdownIcon: false,
  hideSelectedOptions: false,
  hoverOptions: false,
  isClearable: undefined,
  isMulti: false,
  isRequiredTitleLabel: false,
  loadOptions: undefined,
  onMenuClose: null,
  onMenuOpen: null,
  onSelected: null,
  placeholder: null,
  startIcon: null,
  title: undefined,
  titleTooltip: null,
  validate: null,
  wrap: false,
};

SelectGroup.propTypes = {
  'aria-label': PropTypes.string,
  async: PropTypes.bool,
  chipStyles: PropTypes.object,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  field: PropTypes.string.isRequired,
  filterOption: PropTypes.func,
  /** Props applied to the internal FormGroup component. */
  FormGroupProps: PropTypes.object,
  helperText: PropTypes.string,
  hideDropdownIcon: PropTypes.bool,
  hideSelectedOptions: PropTypes.bool,
  hoverOptions: PropTypes.bool,
  isClearable: PropTypes.bool,
  isMulti: PropTypes.bool,
  isRequiredTitleLabel: PropTypes.bool,
  loadOptions: PropTypes.func,
  onMenuClose: PropTypes.func,
  onMenuOpen: PropTypes.func,
  onSelected: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  })).isRequired,
  placeholder: PropTypes.string,
  startIcon: PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.string]),
  title: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
  titleTooltip: PropTypes.string,
  validate: PropTypes.func,
  wrap: PropTypes.bool,
};

export default SelectGroup;
